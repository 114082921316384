import React, { useEffect, useRef, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import Accordion from "@/components/common/accordion";
import SeoHeaders from "@/components/common/seoHeaders";
import { marketingOpenExpertPageHandler } from "@/marketing/utils";
import { isBrowser } from "@/utils/env";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";

import { ACCORDION_DATA } from "../main/FAQ/constants";

import AudioGreeting from "./audioGreeting";
import { EXPERT_REVIEWS_PAGE_SIZE } from "./constants";
import DurationInfo from "./durationInfo";
import ExpertSchedule from "./expertSchedule";
import ExpertScopes from "./expertScope";
import {
  getExpertPageData,
  getExpertPageDataVariables,
} from "./graphql/__generated__/getExpertPageData";
import { GET_EXPERT } from "./graphql/GET_EXPERT";
import Reviews from "./reviews";
import ScrollToSchedule from "./scrollToSchedule";
import TopBlock from "./topBlock";
import { ExpertProps } from "./types";
import { getActualDescription, getActualTitle } from "./utils";
import "./styles.scss";

const Expert = ({ pageContext, location }: ExpertProps) => {
  const {
    expertInitial, prebuiltLocation, seo
  } = pageContext;

  const title = `${expertInitial.getExpert.name} — lunaro.ru`
  const description = expertInitial.getExpert.description.match(/<p>(.*?)<\/p>/g)?.[0]
    .replaceAll(/<\/?p>/g, "")
    .replaceAll("&nbsp;", " ")
    .replaceAll("\n", " ")


  const [isSchedulEmpty, setIsSchedulEmpty] = useState(true);
  const [getExpert, { data, loading, error }] = useLazyQuery<
    getExpertPageData,
    getExpertPageDataVariables
  >(GET_EXPERT, { fetchPolicy: "network-only" });
  const [expert, setExpert] = useState<getExpertPageData>(expertInitial);
  const [isFullInfoShown, setIsFullInfoShown] = useState(false);
  const schedulElementPosition = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getExpert({
      variables: { id: expertInitial.getExpert.id, reviewsNumber: EXPERT_REVIEWS_PAGE_SIZE },
    });
  }, [expertInitial.getExpert.id, getExpert]);

  useEffect(() => {
    if (data && !loading && !error) {
      marketingOpenExpertPageHandler(
        data.getExpert.id,
        data.getExpert.name,
        data.getExpert.rates.call,
        data.getExpert.skills?.map((skill) =>
          skill.name) || [],
        location?.state?.prevPath,
      );
      setExpert(data);
    }
  }, [data, loading, error, location?.state?.prevPath]);

  if (!expert?.getExpert) {
    return null;
  }

  return (
    <>
      <SeoHeaders
        title={seo ? seo.title : getActualTitle(expert.getExpert.name, title)}
        description={seo ? seo.description : getActualDescription(expert.getExpert.description, description ?? "")}
        url={isBrowser() ? window.location.pathname + window.location.search : prebuiltLocation}
        imageUrl={expert.getExpert.cover?.horizontal1x ?? undefined}
      />
      <div className="expert">
        <TopBlock expert={expert.getExpert} />
        <div className="expert__content">
          <div className="expert__content__block-left">
            {expert.getExpert.title && (
              <div className="expert__quote">{expert.getExpert.title}</div>
            )}
            <div className="expert__block-schedul">
              <AudioGreeting expertId={expert.getExpert.id} />
              {!isSchedulEmpty && <ScrollToSchedule scrollPosition={schedulElementPosition} />}
            </div>
            <div
              className={
                isFullInfoShown
                  ? "article expert__description shown"
                  : "article expert__description"
              }
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(expert.getExpert.description),
              }}
            />
            <div
              className="expert__more"
              onClick={() =>
                setIsFullInfoShown((oldState) =>
                  !oldState)}
            >
              {isFullInfoShown ? "Скрыть" : "Показать ещё"}
            </div>
            <ExpertScopes />

            <Reviews
              expertId={expert.getExpert.id}
              rating={expert.getExpert.rating}
              reviewsInitial={expert.getReviews}
            />
            <div className="expert__accordion-desktop">
              <h2 className="expert__accordion-header">Как работает сервис</h2>
              {ACCORDION_DATA.mainEntity.map((item, index) =>
              (
                !item.hideForExpertPage
                && <Accordion key={index} summary={item.name} details={item.answer.text} />
              ))}
            </div>
          </div>

          <div className="expert__content__block-right">
            <ExpertSchedule
              expertUuid={expert.getExpert.uuid}
              scrollElement={schedulElementPosition}
              setIsSchedulEmpty={setIsSchedulEmpty}
            />
            <DurationInfo />
          </div>
          <div className="expert__accordion-mobile ">
            <h2 className="expert__accordion-header">Как работает сервис</h2>
            {ACCORDION_DATA.mainEntity.map((item, index) =>
            (
              !item.hideForExpertPage
              && <Accordion key={index} summary={item.name} details={item.answer.text} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Expert;
