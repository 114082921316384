export const tempAudioMap: { [key: number]: number } = process.env.ENV === "dev"
  ? {
    43: 2,
    42: 35,
    41: 6,
    40: 7,
    55: 10,
    38: 11,
    60: 12,
    44: 13,
    39: 14,
    61: 16,
    36: 69,
    26: 26,
    102: 102,
    103: 103,
    104: 104,
    105: 105,
    106: 106,
    107: 107,
    108: 108,
    109: 109,
    110: 110,
    111: 111,
    112: 112,
    113: 113,
    114: 114,
    115: 115,
    116: 116,
    117: 117,
    118: 118,
    119: 119,
    120: 120,
    121: 121,
    122: 122,
    156: 156,
    157: 157,
    158: 158,
    159: 159,
    160: 160,
    161: 161,
    162: 162,
    163: 163,
    164: 164,
    165: 165,
    166: 166,
    167: 167,
    168: 168,
    169: 169,
    170: 170,
    171: 171,
    173: 173,
    174: 174,
    175: 175,
  }
  : {
    2: 2, // Ирин Сан
    3: 3, // Виталий Дьяконов
    4: 4, // Алёна Белопольская
    5: 5, // Инна Меренкова
    6: 6, // Серафи
    7: 7, // Алёна Опалюк
    9: 9, // Виктория
    10: 10, // Алёна Тарасова
    11: 11, // Елена Вельт
    12: 12, // Ярослава Графова
    13: 13, // Ангелина Форс
    14: 14, // Михаил Романенко
    15: 15, // Оберон
    16: 16, // Анна Микрюкова
    17: 17, // Антонина
    18: 18, // Алика Бирцева
    19: 19, // Анна
    20: 20, // Настин
    21: 21, // Анастасия
    22: 22, // Ксения
    23: 23, // Ольга Ишита
    24: 24, // Юлия Олегова
    25: 25, // Анна Садовская
    26: 26, // Арина Доманская
    27: 27, // Галина
    28: 28, // Дарья
    29: 29, // Ружена
    30: 30, // Алина Юпитер
    31: 31, // Лея
    34: 34, // Елена Миланская
    35: 35, // Лилия
    69: 69, // Евгения Акулова
    102: 102, // Оксана Малькова
    103: 103, // Ульяна Лобода
    104: 104, // Яснина
    105: 105, // Анна Лесная
    106: 106, // Игорь Сагайдак
    107: 107, // Анастасия Виннер
    108: 108, // Кристина Мун
    109: 109, // Елена Никитина
    110: 110, // Юлия Пугачёва
    111: 111, // Юля Ларина
    112: 112, // Александр Кузнецов
    113: 113, // Анна Морана
    114: 114, // Инна Замова
    115: 115, // Кэтрин Ролевски
    116: 116, // Татьяна
    117: 117, // Оксана
    118: 118, // София
    119: 119, // Лорика
    120: 120, // Юлия Акташ
    121: 121, // Диана Лурье
    122: 122, // Алла Герман
    156: 156, // Катерина
    157: 157, // Яна визитка
    158: 158, // Арина Коэн
    159: 159, // Анна Горшкова
    160: 160, // Михаил Пересторонин
    161: 161, // Алёна Кей
    162: 162, // Инга Меликян
    163: 163, // Вячеслав Белов
    164: 164, // Елена
    165: 165, // Марианна
    166: 166, // Екатерина Каменева
    167: 167, // Екатерина Филей
    168: 168, // Марс Вибран
    169: 169, // Галина
    170: 170, // Саша Шу
    171: 171, // Вероника
    173: 173, // Татьяна Гратинская
    174: 174, // Дарья
    175: 175, // Юлия Летягина
  };
